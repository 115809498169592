<template>
  <div>
    <slide-heading :heading="slide.heading" />

    <template v-if="loaded">
      <div v-if="showError">
        <p>{{ errorMessage }}</p>
      </div>
      <template v-else>
        <div v-if="slide.description" class="slide-description">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="slideDescription" />
          <!--eslint-enable-->
        </div>

        <div id="document-download-slide-component">
          <div v-if="loaded && !showError">
            <stageline-pdf-viewer
              v-if="resource"
              ref="pdf-viewer"
              class="stageline-pdf-viewer"
              :read-only="true"
              :show-pagination="true"
              @update:current-page="currentPageUpdated"
            />
          </div>

          <!-- Buttons ---------------------------------------------------------------------------------->
          <div class="w-100 buttons mt-3 mb-0 d-flex flex-column">
            <!-- Show these buttons for full width view -->
            <div v-if="['xs', 'sm'].includes($mq)" class="full-screen-buttons">
              <template>
                <b-button
                  variant="default"
                  class="file-button"
                  @click="printPdf()"
                >
                  <feather-icon type="printer" :stroke-width="2" height="24" width="24" />
                  Print
                </b-button>
                <b-button
                  variant="default"
                  class="file-button"
                  aria-label="download button"
                  @click="downloadPdf()"
                >
                  <feather-icon type="download-cloud" :stroke-width="2" height="24" width="24" />
                  Download
                </b-button>
                <b-button
                  v-if="editEnabled"
                  variant="default"
                  class="file-button"
                  aria-label="edit button"
                  @click="editPdf()"
                >
                  Edit
                </b-button>
              </template>
            </div>

            <div class="slide-viewer-button-row mt-3 mb-0 ">
              <!-- Repeated from above section since order in mobile view is different. -->
              <b-button
                v-if="!editEnabled"
                variant="default"
                aria-label="back button"
                @click="$emit('previous-slide')"
              >
                <fa-icon icon="chevron-left" class="fa-xs" />
                Back
              </b-button>
              <template v-if="!['xs', 'sm'].includes($mq)">
                <b-button
                  variant="default"
                  class="file-button"
                  aria-label="print button"
                  @click="printPdf()"
                >
                  <feather-icon type="printer" :stroke-width="2" height="24" width="24" />
                  Print
                </b-button>
                <b-button
                  variant="default"
                  class="file-button"
                  aria-label="download button"
                  @click="downloadPdf()"
                >
                  <feather-icon type="download-cloud" :stroke-width="2" height="24" width="24" />
                  Download
                </b-button>
                <b-button
                  v-if="editEnabled"
                  variant="default"
                  class="file-button"
                  aria-label="edit button"
                  @click="editPdf()"
                >
                  Edit
                </b-button>
              </template>

              <b-button
                class="next-button"
                variant="primary"
                :aria-label="nextButtonLabel + ' button'"
                @click="next()"
              >
                {{ nextButtonLabel }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </template>
    <ct-centered-spinner v-else>
      {{ loadingText }}
    </ct-centered-spinner>
  </div>
</template>

<script>
import { downloadPrintMixin } from '@/components/StagelineV2/schemaForm/pdf/pdfSchemaFormMixins'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import * as DOMPurify from 'dompurify'

export default {
  name: 'DocumentDownloadSlide',
  components: {
    StagelinePdfViewer: () => import('@/components/StagelineV2/slides/document/StagelinePdfViewer'),
    CtCenteredSpinner:  () => import('@/components/shared/CtCenteredSpinner'),
    SlideHeading:       () => import('@/components/StagelineV2/shared/SlideHeading'),
    FeatherIcon:        () => import('@/components/shared/FeatherIcon'),
  },
  mixins: [ downloadPrintMixin, makeToastMixin ],
  props: {
    slide: null,
  },
  data() {
    return {
      loaded: true,
      savingDocument: false,
      showError: false,
      errorMessage: 'Unable to load Document',
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'formData',
      'resource',
      'currentPage',
      'resourceFields',
    ]),
    ...mapGetters('stageline', [
      'company',
      'slideAgencyResource',
      'isLazyLoadedDocType',
    ]),
    slideResource() {
      return this.slide?.agency_resource
    },
    saveEnabled() {
      return this.slide?.config?.save_enabled
    },
    editEnabled() {
      return this.slide?.config?.edit_enabled
    },
    nextButtonLabel() {
      return this.saveEnabled ?
        'Save & Continue' :
        'Continue'
    },
    loadingText() {
      return this.savingDocument ?
        'Saving Document...' :
        'Loading PDF...'
    },
    slideDescription() {
      return DOMPurify.sanitize(this.slide.description)
    },
  },
  async mounted() {
    this.loaded = false
    let resource = this.slideResource

    // Load PDF for Finalize Legal Docs on mounted since the resource to display varies
    if (this.isLazyLoadedDocType) {
      await this.loadResourceByType()
      resource = this.slideAgencyResource
    }

    if (resource) {
      this.resetStagelineSchemaForm()
      await this.setResource(resource)

      if (this.slide.layout_sub_type === 'initial resolution') {
        await this.setResourceFields(resource.fields)
      } else {
        await this.loadResourceFieldSchema({
          resourceId: resource.id,
        })
      }
    } else {
      this.loaded = true
      this.showError = true
      return
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setCurrentPage',
      'loadResourceFieldSchema',
      'setResource',
      'setResourceFields',
      'resetStagelineSchemaForm',
    ]),
    ...mapActions('stageline', [
      'updateSlideProgress',
      'saveResourceToAccount',
      'goToPdfSlideInCurrentStage',
      'loadResourceByType',
    ]),
    ...mapActions('documents', [
      'fetchDocumentByType',
    ]),
    downloadPdf() {
      this.updateSlideProgress('document_downloaded_at')
      this.download(this.resource, this.formData, this.company)
    },
    async printPdf() {
      await this.updateSlideProgress('document_printed_at')
      await this.print(this.resource, this.formData)
    },
    async editPdf() {
      await this.goToPdfSlideInCurrentStage()
    },
    currentPageUpdated(page) {
      this.setCurrentPage(page)
    },
    async next() {
      if (this.saveEnabled) {
        this.savingDocument = true
        this.loaded = false
        try {
          const result = await this.saveResourceToAccount(this.formData)

          if (!result.data.success) {
            this.errorToast('Error', 'Error saving document')
            return
          }
          this.loaded = true
          this.savingDocument = false
        } catch (error) {
          this.errorToast('Error', 'Error saving document')
          this.loaded = true
          this.savingDocument = false
          return
        }
      }
      this.$emit('next-slide')
    },
  },
}
</script>

<style scoped lang="scss">
#document-download-slide-component {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .stageline-pdf-viewer {
    max-width: 600px;
    width: 100%;
    border-radius: 0.375em;
  }

  .buttons {
    .full-screen-buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.25em;
      button {
        margin-top: 0.5em;
      }
    }
    .slide-viewer-button-row {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-end;
      button {
        margin-left: 0.9375em;
      }
    }
    button.file-button {
      border: 1px $ct-ui-primary solid !important;
    }
    ::v-deep .feather {
      margin-right: 0.375em;
    }
  }
}

.center-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 3.75em;
}

.max-button {
  max-width: 300px;
}

.slide-description {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}


@media only screen and (max-width: 767px) {
  #document-download-slide-component {

    .buttons {

      .slide-viewer-button-row {
        justify-content: space-between;
      }
    }
  }
}
</style>
